<!--
  删除小组弹框
-->
<template>
  <el-dialog
    :visible.sync="delThisGroup"
    :title="$t('vue_label_chatter_delete_group_title')"
    :before-close="handleClose"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div>
        <!-- <p>确定要删除小组"{{groupInfoList.name}}"吗?</p> -->
        <p>
          {{
            $t("vue_label_chatter_delete_some_group", {
              groupInfoList: groupName,
            })
          }}
        </p>
        <!-- <p>删除小组后将删除小组中的所有信息，但不会删除小组文件</p> -->
        <p>{{ $t("vue_label_chatter_notice1") }}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="delThisGroupCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="delThisGroupConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    delThisGroupTo: {
      type: Boolean,
      default: false,
    },
    groupName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      delThisGroup: false,
    };
  },
  watch: {
    delThisGroupTo: {
      handler: function (newVal) {
        this.delThisGroup = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("delThisGroupCancel");
    },
    delThisGroupCancel() {
      this.$emit("delThisGroupCancel");
    },
    delThisGroupConfirm() {
      this.$emit("delThisGroupConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
  // height: 390px;
}
.dialog-body {
  div {
    p:first-child {
      font-size: 16px;
      color: #686868;
    }
    p:last-child {
      color: #ff3c32;
      font-size: 12px;
      word-break: break-word; // 英文换行
    }
  }
}
</style>
