<!--
  成员管理
-->
<template>
  <el-dialog
    :visible.sync="membersManage"
    :title="$t('label.campaignmember.main.manageMember')"
    :before-close="membersManageCancel"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="552"
  >
    <div class="dialog_body">
      <!-- tab及搜索框 -->
      <div class="div_title">
        <el-tabs v-model="form.activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="$t('chatter.group.allTheMembers')"
            name="all"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('label_partner_workspace_member')"
            name="member"
          ></el-tab-pane>
        </el-tabs>
        <div class="searchPerson">
          <el-input
            :placeholder="$t('label.lookup.user')"
            v-model="form.keyWord"
            @input="getKeyWord"
          >
          </el-input>
          <!-- 查询 -->
          <button @click="getNewPerson">{{ $t("chatter.group.query") }}</button>
        </div>
      </div>
      <!-- 成员列表 -->
      <div class="div_content">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          v-loadmore="loadMore"
        >
          <el-table-column :label="$t('chatter.group.userName')" width="180">
            <template slot-scope="scope">
              {{ scope.row.name || scope.row.userName }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('chatter.group.theMembership')"
            width="180"
          >
            <template slot-scope="scope">
              <div
                class="ownerSp"
                v-if="
                  (scope.row.isNumber === true ||
                    scope.row.userstatus === 'normal') &&
                  (scope.row.id === createdUserId ||
                    scope.row.userid === createdUserIdGroup)
                "
              >
                {{ $t("label.referperson") }}
              </div>
              <div v-else>
                <!-- 添加 -->
                <el-button
                  type="text"
                  v-if="scope.row.isNumber === false"
                  @click="addPeopleToGroup(scope.row.id, scope.row.userid)"
                  >{{ $t("label.campaignmember.main.addMember1") }}</el-button
                >
                <!-- 取消添加 -->
                <el-button
                  @click="delPeopleToGroup(scope.row.id, scope.row.userid)"
                  type="text"
                  v-if="
                    scope.row.isNumber === true ||
                    scope.row.userstatus === 'normal'
                  "
                  >{{ $t("vue_label_chatter_cancel_adding") }}</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('label_tabpage_rolez')">
            <template slot-scope="scope">
              <div
                class="ownerSp"
                v-if="
                  scope.row.id === createdUserId ||
                  scope.row.userid === createdUserIdGroup
                "
              >
                {{ $t("label.referperson") }}
              </div>
              <el-select
                v-else
                @change="changeUserRole"
                v-model="scope.row.userrole"
                :disabled="scope.row.userrole === ''"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="membersManageConfirm">{{
        $t("chatter.group.complete")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
//api
import {
  joinGroup, //添加CCChat小组成员
  removeGroupMember, ////删除(取消添加)CCChat小组成员
  changeUserRole, //修改CCChat小组成员角色
} from "../../api.js";
export default {
  props: {
    membersManageTo: {
      type: Boolean,
      default: false,
    },
    userListData: {
      type: Array,
      default: () => [],
    },
    queryUserResData: {
      type: Array,
      default: () => [],
    },
    newGroupId: {
      type: String,
      default: "",
    },
    //当前登录用户ID -- 全部人员
    createdUserId: {
      type: String,
      default: "",
    },
    //当前登录用户ID -- 小组中
    createdUserIdGroup: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      membersManage:false,
      form: {
        activeName: "all", // tab选择项
        keyWord: "", // 搜索关键字
      },
      personId: "", //人员ID
      userId: "", //成员ID
      tableData: [],
      options: [
        {
          label: this.$i18n.t("label_partner_workspace_member"),
          value: "member",
        },
        {
          label: this.$i18n.t("label.manager"),
          value: "manager",
        },
      ],
      value: "",
    };
  },
  watch: {
    membersManageTo: {
      handler: function (newVal) {
        this.membersManage = newVal;
      },
      immediate: true,
    },
    // 监听全部人员数据的改变，及时赋值更新数据
    queryUserResData: {
      handler: function () {
        if (this.form.activeName == "all") {
          this.tableData = this.queryUserResData;
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听小组成员数据的改变，及时赋值更新数据
    userListData: {
      handler: function () {
        if (this.form.activeName == "member") {
          this.tableData = this.userListData;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  directives: {
    //滚动加载
    loadmore: {
      bind(el, binding) {
        el.addEventListener("scroll", function () {
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= 0) {
            binding.value();
          }
        });
      },
    },
  },
  methods: {
    // 切换tab
    handleClick(tab) {
      if (tab.name == "all") {
        this.tableData = this.queryUserResData;
      } else if (tab.name == "member") {
        this.tableData = this.userListData;
      }
    },
    // 获取输入的值
    getKeyWord() {
      if (!this.form.keyWord) {
        this.$emit("clearKeyWord");
        this.$bus.$emit("getGroupMembers");
      }
    },
    // 查询方法
    getNewPerson() {
      this.$emit("getNewPerson", this.form.keyWord);
    },
    //取消
    //滚动加载
    loadMore() {
      this.$emit("loadMore");
    },
    //添加为小组成员
    async addPeopleToGroup(id, uId) {
      this.personId = id;
      this.userId = uId;
      await joinGroup({
        groupId: this.newGroupId,
        userId: this.personId || this.userId,
        joinType: "normal",
      });
      this.$bus.$emit("getGroupMembers");
    },
    //取消添加为小组成员
    async delPeopleToGroup(id, uId) {
      this.personId = id;
      this.userId = uId;
      await removeGroupMember({
        groupId: this.newGroupId,
        userId: this.personId || this.userId,
      });
      this.$bus.$emit("getGroupMembers");
    },
    //修改CCChat小组成员角色
    async changeUserRole(val) {
      if (val === "member") {
        await changeUserRole({
          groupId: this.newGroupId,
          userId: this.personId || this.userId,
          isChecked: "false",
        });
      } else if (val === "manager") {
        await changeUserRole({
          groupId: this.newGroupId,
          userId: this.personId || this.userId,
          isChecked: "true",
        });
      }
      this.$bus.$emit("getGroupMembers");
    },
    membersManageCancel() {
      this.$emit("membersManageCancel");
    },
    //完成
    membersManageConfirm() {
      this.$emit("membersManageConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_body {
  .div_title {
    ::v-deep .el-tabs__header {
      margin: 0 !important;
      .el-tabs__nav-wrap::after {
        width: 26%;
      }
    }
    ::v-deep .el-tabs__content {
      padding-top: 10px !important;
    }
    .searchPerson {
      display: flex;
      align-items: center;
      button {
        margin-left: 4px;
        height: 24px;
        outline: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
    ::v-deep .el-input {
      width: 145px;
      height: 28px;
      .el-input__inner {
        height: 28px;
        border-radius: 3px;
      }
    }
    ::v-deep .el-input__suffix {
      right: 0 !important;
    }
  }
  .div_content {
    margin-top: 10px;
    .ownerSp {
      height: 40px;
      line-height: 40px;
      padding-left: 2px;
    }
    .el-table {
      max-height: 254px;
      overflow: auto;
    }
    ::v-deep .el-table td {
      padding: 0 !important;
    }
  }
  ::v-deep .el-select {
    width: 80px;
    height: 24px;
  }
  ::v-deep .el-input__inner {
    height: 24px;
    line-height: 24px;
    padding-right: 20px !important;
  }
  ::v-deep .el-input__suffix-inner {
    line-height: 24px !important;
    height: 24px !important;
  }
  ::v-deep .el-input__icon {
    line-height: 24px !important;
    height: 24px !important;
    width: 12px;
  }
}
</style>
