<!--
 * @Author: your name
 * @Date: 2021-12-03 14:36:43
 * @LastEditTime: 2022-06-24 14:38:07
 * @LastEditors: 张亚欣 18911148524@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\colleagueCircle\components\dynamic-groupList\ThisPeopleState.vue
-->
<!--
  当前用户与小组的状态弹框
-->
<template>
  <el-dialog
    :visible.sync="thisPeopleState"
    :title="isStatus"
    :before-close="thisPeopleStateCancel"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div>
        <p>
          {{
            $t("vue_label_chatter_certification", {
              isStatus: isStatus,
              titleName: titleName,
            })
          }}
        </p>
        <p
          v-show="
            isStatus == $t('label_tabpage_exitz') ||
            isStatus == $t('chatter.group.cancelRequest')
          "
        >
          {{ $t("vue_label_norm_undone") }}
        </p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="thisPeopleStateCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="thisPeopleStateConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    thisPeopleStateTo: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "",
    },
    isStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      thisPeopleState: false,
    };
  },
  watch: {
    thisPeopleStateTo: {
      handler: function (newVal) {
        this.thisPeopleState = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    thisPeopleStateCancel() {
      this.$emit("thisPeopleStateCancel");
    },
    thisPeopleStateConfirm() {
      this.$emit("thisPeopleStateConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
  // height: 390px;
}
.dialog-body {
  div {
    p:first-child {
      font-size: 16px;
      color: #686868;
    }
    p:last-child {
      color: #ff3c32;
      font-size: 12px;
    }
  }
}
</style>
